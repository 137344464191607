import React, {useState, useEffect, useContext} from "react";
import { graphql } from "gatsby";
import produce from "immer";
import moment from "moment";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import { URL, POSTS_PER_PAGE as postsPerPage } from "../constants";
import Header from "../components/Header";
import TriangleFilter from "../style/Header/triangleFilter";
import { Heading } from "../style/Heading";
import { Text } from "../style/Text";
import { Pagination } from "../style/Pagination";
import { Flex, Wrapper } from "../style/Grid";
import { ButtonFilter } from "../style/ButtonFilter";
import TopicLinks from "../components/TopicLinks";
import { Article } from "../style/Article";
import BubbleNewsletter from "../components/BubbleNewsletter";
import Button from "../components/Button";
import { ImageTileSmall } from "../style/ImageTile/ImageTileSmall";
import TileSocial from "../components/TileSocial";
import FilterSidebar from "../components/Filter/FilterSidebar";
import usePageView from "../utils/usePageView";
import { blogFilterArray } from "../constants";
import {filterContext} from "../utils/filterStateProvider";
import { Image } from "../style/Image";

const Blog = ({ data, location, pageContext }) => {
  usePageView(location.pathname, "Články a podcasty", "");
  require("moment/locale/cs");

  const {setBlogPagesToShow, setBlogFilter, blogFilter, blogPagesToShow} = useContext(filterContext);
  const pagesToShow = blogPagesToShow.length > 0 ? blogPagesToShow : [pageContext.currentPage ? pageContext.currentPage : 1]

  const person = {
    name: "Sandra Jirásková",
    position: "Recruitment manager",
    description: "Máš zájem o newsletter plný inspirace?",
    left: false,
  };

  const maxNumOfPagesToRender = 7;

  const posts = data.allMdx.nodes.filter(
    ({ frontmatter: { date } }) =>
      !moment(date, "YYYY-MM-DD, HH:mm Z").isAfter()
  );
  const [filterShow, setShowFilter] = useState(false);
  const [nextButtonShow, setShowNextButton] = useState(true);
  const [hideCertainFilter, setHideCertainFilter] = useState((blogFilter.type && blogFilter.type[0] === "podcast") ? "category" : "");


  let filteredPosts = posts;

  if (Object.keys(blogFilter).length !== 0) {
    filteredPosts = posts.filter(post => {
      let include = true;
      Object.entries(blogFilter).forEach(([key, val]) => {
        if (val.length > 0) {
          let difference = val.filter(x =>  post?.frontmatter[key] && post.frontmatter[key].includes(x));
          if (difference.length < 1) {
            include = false;
          }
        }
      });
      return include;
    });
  }

  const getLastPage = () => pagesToShow.slice(-1).pop();

  const resetFilter = () => {
    setBlogFilter({});
    setHideCertainFilter("");
  };

  const handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;

    const nextState = produce(blogFilter, draftState => {
      let index = draftState[name] && draftState[name].indexOf(value);

      if (draftState[name] && index > -1) {
        draftState[name].splice(index, 1);
      } else if (draftState[name] && index < 0) {
        draftState[name].push(value);
      } else {
        draftState[name] = [value];
      }
    });

    let types = [...blogFilterArray[0].checkboxes];
    const tempIndex = types.findIndex(type => type.value === value);

    if (tempIndex >= 0 && event.target.checked) {
      if (
        types[tempIndex].value === "blog" &&
        blogFilter["category"] && blogFilter["category"].length !== 0
      ) {
        setBlogFilter(nextState);
      } else {
        setBlogFilter({ type: [types[tempIndex].value] });
      }

      if (types[tempIndex].value === "podcast") {
        setHideCertainFilter("category");
      } else {
        setHideCertainFilter("");
      }
    } else {
      setBlogFilter(nextState);
    }

    setBlogPagesToShow([1]);
    window.history.pushState({ activePage: 1 }, "", "/clanky");
  };
  const handleShowFilter = () => setShowFilter(!filterShow);
  const handlePageChange = (e, number) => {
    e.preventDefault();
    window.scrollTo({
      top:
        document.querySelector("#blogContent").offsetTop -
        document.querySelector("nav").offsetHeight -
        50,
      left: 0,
      behavior: "smooth",
    });
    window.history.pushState(
      { activePage: number },
      "",
      `/clanky/${number !== 1 ? number : ""}`
    );
    setBlogPagesToShow([number]);
  };

  const showMorePosts = () => {
    window.history.pushState(
      { activePage: getLastPage() + 1 },
      "",
      `/clanky/${getLastPage() + 1}`
    );
    setBlogPagesToShow([...pagesToShow, getLastPage() + 1]);
  };

  const createPagingRender = (postsPerPage, totalPosts) => {
    const totalPages = Math.ceil(totalPosts / postsPerPage);
    const activePage = getLastPage();

    const pageObject = {
      totalPages,
      array: [],
    };

    if (pageObject.totalPages <= maxNumOfPagesToRender) {
      for (let i = 1; i <= pageObject.totalPages; i++) {
        pageObject.array.push(i);
      }
    } else {
      pageObject.array.push(1);

      if (activePage <= maxNumOfPagesToRender - 3) {
        for (let i = 2; i <= maxNumOfPagesToRender - 2; i++) {
          pageObject.array.push(i);
        }
        pageObject.array.push("...");
      } else if (
        activePage >= pageObject.totalPages - (maxNumOfPagesToRender - 3) &&
        activePage >= pageObject.totalPages - (maxNumOfPagesToRender - 4)
      ) {
        pageObject.array.push("...");
        for (
          let i = pageObject.totalPages - (maxNumOfPagesToRender - 3);
          i <= pageObject.totalPages - 1;
          i++
        ) {
          pageObject.array.push(i);
        }
      } else {
        pageObject.array.push("...");
        pageObject.array.push(activePage - 1);
        pageObject.array.push(activePage);
        pageObject.array.push(activePage + 1);
        pageObject.array.push("...");
      }

      pageObject.array.push(pageObject.totalPages);
    }
    return pageObject;
  };

  const createPages = (posts, postsPerPage) => {
    const numberOfPages = Math.ceil(posts.length / postsPerPage);

    const pages = [];
    for (let i = 0; i < numberOfPages; i++) {
      pages[i] = [];
      let helperI = i * postsPerPage;
      let limit = helperI + postsPerPage;
      for (let j = helperI; j < limit; j++) {
        if (posts[j]) {
          pages[i].push(posts[j]);
        }
      }
    }
    return pages;
  };

  const postsInPages = createPages(filteredPosts, postsPerPage);

  const pagesToRender = createPagingRender(postsPerPage, filteredPosts.length);

  useEffect(() => {
    if (getLastPage() === postsInPages.length || postsInPages.length <= 0) {
      setShowNextButton(false);
    } else {
      setShowNextButton(true);
    }
  }, [blogFilter, pagesToShow]);

  return (
    <Layout title="blog" location={location}>
      <SEO
        title={"Články a podcasty"}
        description={
          "Poznej budoucí kolegy, zjisti, na čem pracujeme a jak to u nás funguje"
        }
      />

      {!filterShow && (
        <>
          <Header
            bgImage={data.headerImage}
            bottomComponents={<TriangleFilter />}
            containerJustifyContent="flex-end"
            minHeight={["430px", null, "545px", null, null]}
            shadowAfter={true}
          >
            <Heading
              as="h1"
              color="white.100"
              fontSize={[5, null, "40px", "48px", null]}
              fontWeight="light"
              lineHeight={[1, null, "44px", "64px", null]}
              maxWidth={["100%", "400px", "560px", null, null]}
              mb={[4, 5, null, 6, null]}
            >
              Poznej budoucí kolegy, zjisti, na&nbsp;čem&nbsp;pracujeme a jak to
              u&nbsp;nás funguje
            </Heading>
          </Header>
          <Flex
            display={["flex", null, null, "none", null]}
            backgroundColor="EYYellow.default"
            height="80px"
            px="32px"
            py="16px"
          >
            <ButtonFilter onClick={handleShowFilter}>
              Filtrovat ({filteredPosts.length})
            </ButtonFilter>
          </Flex>
        </>
      )}
      <Flex
        flexDirection={["column", null, "row", null, null]}
        background={[
          "none",
          null,
          null,
          `linear-gradient(90deg, #ffe600 38%, #FFF 31.25%)`,
          `linear-gradient(90deg, #ffe600 31.25%, #FFF 31.25%)`,
        ]}
      >
        <Flex
          width={["100%", null, null, null, "1217px"]}
          margin="0 auto"
          background={[
            "none",
            null,
            null,
            `linear-gradient(90deg, #ffe600 38%, #FFF 31.25%)`,
            `linear-gradient(90deg, #ffe600 31.25%, #FFF 31.25%)`,
          ]}
        >
          <FilterSidebar
            pagesToShow={pagesToShow}
            setBlogPagesToShow={setBlogPagesToShow}
            filterShow={filterShow}
            resetFilter={resetFilter}
            handleChange={handleChange}
            filterData={blogFilter}
            handleShowFilter={handleShowFilter}
            type="blog"
            hideCertainFilter={hideCertainFilter}



          />
          {!filterShow && (
            <Flex
              width={["100%", null, "100%", "68.75%", null]}
              flexDirection="column"
              alignItems="flex-start"
            >
              <Flex
                maxWidth={["100%", null, "100%", "calc(100% - 81px)", "90%"]}
                width={[
                  "calc(100% - 31px)",
                  null,
                  null,
                  "calc(100% - 81px)",
                  "100%",
                ]}
                flexDirection="row"
                flexWrap="wrap"
                justifyContent={[
                  "center",
                  "flex-start",
                  "space-between",
                  null,
                  "flex-start",
                ]}
                ml={["16px", null, null, "50px", "10%"]}
                mr={["16px", null, null, "30px", 0]}
                mt={["20px", null, "40px", 0, 0]}
                mb={["64px", null, null, null, null]}
                id="blogContent"
              >
                {postsInPages.length > 0 &&
                  pagesToShow.map(page =>
                    postsInPages[page - 1].map(
                      ({
                        id,
                        fields: { slug },
                        frontmatter: { thumbnailPostImage, title },
                      }) => {
                        return (
                          <ImageTileSmall
                            key={id}
                            to={`${URL.blog}${slug}`}
                            height={["350px", null, null, "300px", "380px"]}
                            width={["100%", "45%", "46%", "48%", null]}
                          >
                            <Heading
                              as="h3"
                              color="white.100"
                              fontSize={["18px", null, null, "4", null]}
                              lineHeight={["24px", null, null, "30px", null]}
                              fontWeight="light"
                              mb={["10px", null, "14px", null, null]}
                              width="100%"
                              maxWidth="100%"
                              maxHeight={["48px", null, null, "90px", null]}
                              overflow="hidden"
                            >
                              {title}
                            </Heading>
                            <Text
                              to={URL.jobs}
                              color="EYYellow.default"
                              fontSize={["1", null, "2", null, null]}
                            >
                              Chci vědět víc
                            </Text>
                            {thumbnailPostImage?.childImageSharp && (
                              <Image image={thumbnailPostImage} cover/>
                            )}
                          </ImageTileSmall>
                        );
                      }
                    )
                  )}
              </Flex>
              <Flex
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                width="100%"
              >
                {nextButtonShow && (
                  <Button
                    as="button"
                    variant="outlineBlack"
                    maxWidth="300px"
                    onClick={showMorePosts}
                    width={["264px", "280px", null, null, null]}
                    fontWeight="300"
                    fontSize="14px"
                  >
                    Chci číst další články
                  </Button>
                )}
                <Pagination
                  mt="15px"
                  marginBottom={["64px", null, null, "120px", null]}
                  width={["264px", "280px", null, null, null]}
                >
                  {pagesToRender.array.map(number => {
                    const current = number === getLastPage() && "page";
                    const outline =
                      number === getLastPage() ? "outlineBlack" : "outlineNone";
                    return (
                      <li aria-current={current}>
                        {number === "..." ? (
                          <span>{number}</span>
                        ) : (
                          <Button
                            href={`/clanky/${number !== 1 ? number : ""}`}
                            variant={outline}
                            style={
                              current
                                ? { cursor: "default", pointerEvents: "none" }
                                : {}
                            }
                            maxWidth="300px"
                            onClick={e => handlePageChange(e, number)}
                            fontWeight={current ? "700" : "300"}
                            fontSize="14px"
                            py="8px"
                            px={["14px", "16px", "16px", "16px", null]}
                          >
                            {number === getLastPage() ? number : number}
                          </Button>
                        )}
                      </li>
                    );
                  })}
                </Pagination>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
      {!filterShow && (
        <>
          <Wrapper as="section" bg="EYOffBlack.default">
            <Article
              mt={["64px", null, null, "120px", null]}
              mb={["64px", null, null, "120px", null]}
              width={["90%", "90%", null, null, "90%"]}
            >
              <BubbleNewsletter
                personImage={data.newsletterBubblePersonImage}
                {...person}
              />
            </Article>
          </Wrapper>

          <section>
            <Flex>
              <TileSocial
                to={URL.jobs}
                width="91%"
                tileColor="light"
                tileOrientation="right"
              />
            </Flex>
          </section>

          <TopicLinks
            as="section"
            mt="0"
            items={[
              {
                heading: "Výběrko do EY očima nováčků",
                link: `${URL.blog}/vyberko-do-ey-ocima-novacku`,
                image: data.topicLinksImage1,
              },
              {
                heading: "4 rady, jak zvládnout pohovor do EY",
                link: `${URL.blog}/60-4-rady-jak-zvladnout-pohovor-do-ey`,
                image: data.topicLinksImage2,
              },
            ]}
          >
            Možná ti přijde vhod
          </TopicLinks>
        </>
      )}
    </Layout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query postQuery {
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" }, publish: { eq: true } }
      }
    ) {
      nodes {
        excerpt(pruneLength: 400)
        id
        fields {
          slug
        }
        frontmatter {
          title
          templateKey
          date(formatString: "YYYY-MM-DD, HH:mm Z")
          type
          category
          publish
          thumbnailPostImage {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 362, quality: 90)
            }
          }
        }
      }
    }
    headerImage: file(relativePath: {eq: "header-backgrounds/blog-bg.jpg"}) {
      id
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }
    newsletterBubblePersonImage: file(relativePath: {eq: "recruiters/SandraJiraskova.png"}) {
      id
      childImageSharp {
        gatsbyImageData(width: 88, height: 88, quality: 90, layout: FIXED)
      }
    }
    topicLinksImage1: file(relativePath: {eq: "vyberko-do-ey-ocima-novacku.jpg"}) {
      id
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }
    topicLinksImage2: file(relativePath: {eq: "4-rady-jak-na-vyberko-do-ey.jpg"}) {
      id
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }
  }
`;
