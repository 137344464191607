import styled from "styled-components";
import {
  space,
  color,
  layout,
  typography,
  flexbox,
  background,
} from "styled-system";
import propTypes from "@styled-system/prop-types";
import { themeGet } from "@styled-system/theme-get";

import { Link as GatsbyLink } from "gatsby";
import React from "react";

const ImageTileSmall = styled(GatsbyLink)`
  position: relative;
  display: flex;
  text-decoration: none;
  background-size: auto 100%;
  background-position: center center;
  background-repeat: no-repeat;
  transition: background-size ${themeGet("times.transition")};
  overflow: hidden;

  ${space}
  ${flexbox}
  ${color}
  ${layout}
  ${typography}
  ${background}

  margin-bottom: 24px;

  &:nth-of-type(odd) {
    margin-right: 8px;
    @media screen and (max-width: ${themeGet("breakpoints.lg")}) {
      margin-left: 16px;
    }
    @media screen and (max-width: ${themeGet("breakpoints.md")}) {
      margin-right: 2%;
    }
    @media screen and (max-width: ${themeGet("breakpoints.sm")}) {
      margin-right: 16px;
    }
  }

  &:nth-of-type(even) {
    margin-left: 8px;
    @media screen and (max-width: ${themeGet("breakpoints.lg")}) {
      margin-right: 16px;
    }
    @media screen and (max-width: ${themeGet("breakpoints.md")}) {
      margin-left: 2%;
    }
    @media screen and (max-width: ${themeGet("breakpoints.sm")}) {
      margin-left: 16px;
    }
  }

  &:last-of-type {
    &:nth-of-type(odd) {
      margin-right: 0;
      margin-left: 0;
      @media screen and (max-width: ${themeGet("breakpoints.lg")}) {
        margin-left: 16px;
      }
      @media screen and (max-width: ${themeGet("breakpoints.md")}) {
      }
      @media screen and (max-width: ${themeGet("breakpoints.sm")}) {
        margin-left: 16px;
        margin-right: 16px;
      }
    }
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-image: linear-gradient(180deg, rgba(46, 46, 56, 0) 0%, #2e2e38 90%, #2e2e38 100%);
  }

  .image {
    transition: transform ${themeGet("times.transition")};
  }

  &:hover {
    .image {
      transform: scale(1.1);
    }
  }

  & > * {
    position: relative;
    z-index: 11;
  }
`;

ImageTileSmall.displayName = `ImageTileSmall`;

ImageTileSmall.defaultProps = {
  height: "380px",
  flexDirection: "column",
  justifyContent: "flex-end",
  padding: ["25px", null, null, "25px", null],
};

ImageTileSmall.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.flexbox,
  ...propTypes.background,
};

export { ImageTileSmall };
