import styled from "styled-components";
import { space, color, layout, typography, flexbox } from "styled-system";
import propTypes from "@styled-system/prop-types";

import { themeGet } from "@styled-system/theme-get";

const TileSocial = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color ${themeGet("times.transition")};

  ${space}
  ${flexbox}
  ${color}
  ${layout}
  ${typography}

  background-color: ${props => props.tileColor};

  ${props =>
    props.tileColor === "light" &&
    `
    &:hover {
      background-color: ${props.theme.colors.EYYellow.hover};
    }
  `}

  ${props =>
    props.tileColor === "dark" &&
    `
    &:hover {
      background-color: ${props.theme.colors.EYOffBlack.hover};
    }
  `}

  & > div > p {
    text-align: right;
  }

  svg {
    path {
      &.bg {
        fill: ${themeGet("colors.EYOffBlack.default")};
      }
      &.inside {
        fill: ${themeGet("colors.EYYellow.default")};
      }
    }
    &:hover {
      path.bg {
        fill: #1a1a24;
      }
    }
  }
`;

TileSocial.displayName = `TileButton`;

TileSocial.defaultProps = {
  width: "100%",
  justifyContent: "flex-start",
  lineHeight: "1",
  fontSize: "6",
  fontWeight: "light",
};

TileSocial.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.flexbox,
};

export { TileSocial };
