import styled from "styled-components";
import { space, color, layout, typography, flexbox } from "styled-system";
import propTypes from "@styled-system/prop-types";
import { themeGet } from "@styled-system/theme-get";

const Pagination = styled.ul`
  list-style-type: none;
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 0;

  a {
    padding: 8px 20px !important;
  }

  li, li[aria-current="page"] {
    display: table;
    span {
      font-size: 14px;
      line-height: 50px;
      padding: 8px 13px;
      @media screen and (max-width: ${themeGet("breakpoints.sm")}) {
        padding-right: 14px;
        padding-left: 14px;
      }
    }
  }

  ${space}
  ${color}
  ${layout}
  ${typography}
  ${flexbox}
`;

Pagination.defaultProps = {
  fontSize: 3,
  fontWeight: "light",
  textAlign: "left",
  listStyleType: "none",
};

Pagination.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.flexbox,
};

export { Pagination };
