import React from "react";

import { TileSocial as TileSocialStyle } from "../../style/TileSocial";
import { Flex } from "../../style/Grid";
import { Text } from "../../style/Text";
import {
  RoundedApplePodcastIcon,
  RoundedFacebookIcon,
  RoundedInstagramIcon,
  RoundedLinkedInIcon,
  RoundedSpotifyIcon,
  RoundedTwitterIcon,
  RoundedYoutubeIcon,
} from "../Icons/RoundedIcon";
import styled from "styled-components";

const Social = styled.a`
  width: 48px;
  height: 48px;
  display: table;
`;

const TileSocial = (props) => {
  const { to, tileColor = "light", tileOrientation = "left", children } = props;

  return (
    <TileSocialStyle
      type={tileColor}
      color={tileColor === "light" ? "EYOffBlack.tile" : "white.100"}
      bg={tileColor === "light" ? "EYYellow.default" : "EYOffBlack.tile"}
      flexDirection={"column"}
      justifyContent="center"
      alignItems="flex-end"
      px={["15px", "30px", "4.5%", "4.5%", null]}
      fontSize={["2", "3", "5", null, null]}
      minHeight={["240px", null, "240px", null, null]}
    >
      <Text mb="24px" textAlign="right">
        Sleduj další příběhy ze života EY na našich sítích
      </Text>
      <Flex
        width={["100%", null, null, "50%", "40%"]}
        justifyContent={["flex-end", null, null, null, null]}
        flexWrap="wrap"
        style={{ gap: "16px" }}
      >
        <Social
          title="Facebook"
          href="https://www.facebook.com/EYCareersCzech"
          target="_blank"
        >
          <RoundedFacebookIcon />
        </Social>
        <Social
          title="Instagram"
          href="https://www.instagram.com/eyceskarepublika/"
          target="_blank"
        >
          <RoundedInstagramIcon />
        </Social>
        <Social
          title="Twitter"
          href="https://twitter.com/EY_CeskaRep"
          target="_blank"
        >
          <RoundedTwitterIcon />
        </Social>
        <Social
          title="Youtube"
          href="https://www.youtube.com/channel/UCOfL_0Rr5qQRN66_VoXylGA"
          target="_blank"
        >
          <RoundedYoutubeIcon />
        </Social>
        <Social
          title="LinkedIn"
          href="https://www.linkedin.com/company/ernstandyoung"
          target="_blank"
        >
          <RoundedLinkedInIcon />
        </Social>
        <Social
          title="Spotify"
          href="https://open.spotify.com/show/4jQrwnlNtBIbQNP0Py5G80"
          target="_blank"
        >
          <RoundedSpotifyIcon />
        </Social>
        <Social
          title="Apple Podcasts"
          href="https://podcasts.apple.com/us/podcast/cesta-s-ey/id1456544252"
          target="_blank"
        >
          <RoundedApplePodcastIcon />
        </Social>
      </Flex>
    </TileSocialStyle>
  );
};

export default TileSocial;
